let ZoomProduto = (function(){
	let produtoZoom = document.getElementById('zoom-image');

	if(produtoZoom){
		let zoomJs = document.createElement('script');

		zoomJs.src = 'https://cdnjs.cloudflare.com/ajax/libs/elevatezoom/3.0.8/jquery.elevatezoom.min.js';

		document.body.appendChild(zoomJs);

		zoomJs.addEventListener('load', function(){
			$('#zoom-image').elevateZoom({
				gallery: 'gallery',
				zoomType: "lens",
				cursor: 'pointer',
				scrollZoom : true
			});
			

			$('#zoom-image').bind('click', function(e){
				let ez = $('#zoom-image').data('elevateZoom');

				$.fancybox(ez.getGalleryList());

				return false;
			})
		});
	}
})();